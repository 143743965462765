import React from "react";
import { Link, useHistory } from "react-router-dom";

const DirectAccessListModal = ({ accessList = [] }) => {
  const history = useHistory();
  return (
    <div
      className="modal fade"
      id="bookmark_modal"
      tabindex="-1"
      aria-labelledby="bookmark_modalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog">
        <div className="modal-content p-4 rounded-15 bg-white">
          <div className="modal-header border-0 justify-content-between p-0 mb-3">
            <h5
              className="modal-title fs-lg text-black fw-semibold mb-0"
              id="bookmark_modalLabel"
            >
              Bookmarks
            </h5>
            <div className="modal_actions d-flex align-items-center gap-3 justify-content-end">
              <Link
                to="#"
                data-bs-dismiss="modal"
                aria-label="Close"
                className="d-block fs-lg text-gray lh-1"
                onClick={() => {
                  history.push("/admin/directaccess");
                }}
              >
                <span className="material-symbols-outlined"> edit </span>
              </Link>

              <button
                type="button"
                className="close body-bg rounded-circle d-flex align-items-center justify-content-center fs-lg border-0 text-gray p-0 m-0"
                style={{ height: "34px", width: "34px", minWidth: "34px" }}
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <span className="material-symbols-outlined"> close </span>
              </button>
            </div>
          </div>
          <div className="modal-body p-0">
            <ul className="list-style-none d-flex flex-wrap gap-2">
              {accessList.map((accessItem, index) => (
                <li key={index}>
                  <Link
                    to="#"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    className="d-flex gap-2 text-gray fs-sm gap-2 body-bg py-2 px-2 rounded-8 "
                    onClick={() => {
                      history.push(`${accessItem.accessurl}`);
                    }}
                  >
                    <span className="d-block">{accessItem.title}</span>
                    <i className="d-block material-symbols-outlined text-gray fs-md">
                      open_in_new
                    </i>
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DirectAccessListModal;
